
import { computed, defineComponent, PropType } from 'vue'

import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'SiderItem',
  props: {
    icon: {
      type: String as PropType<string>,
      required: true
    },
    title: {
      type: String as PropType<string>,
      required: true
    },
    iconActive: {
      type: String as PropType<string>,
      required: true
    },
    path: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup() {
    const route = useRoute()

    const currentPath = computed(() => route.path)

    return {
      currentPath
    }
  }
})
