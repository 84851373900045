import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23e87621"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sider-item-icon" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "sider-item-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "sider-item",
    to: _ctx.path
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.currentPath.indexOf(_ctx.path) > -1)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.iconActive
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.icon
            }, null, 8, _hoisted_3))
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}